@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0px;
    padding: 0px;
    font-family: 'Courier';
    min-height: 100vh;
    align-items: center;
  }
  // code[class*="language-"], pre[class*="language-"]{
    // font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace
  // }
  
  h1{
    font-size: 42px;
  }
  h2{
    font-size: 32px;
  }
  h3{
    font-size: 26px;
  }
  h4{
    font-size: 22px;
  }
  h5{
    font-size: 18px;
  }
  h6{
    font-size: 14px;
  }


@media only screen and (min-device-width: 768px) {
    .tiny-scrollbar {
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
    }

    .tiny-scrollbar::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: transparent;
    }

    .tiny-scrollbar::-webkit-scrollbar-thumb {
        border-radius: 10px;
        @apply bg-fg-base/20;
    }

    .tiny-scrollbar::-webkit-scrollbar-track-piece:start {
        background: transparent;
    }

    .tiny-scrollbar::-webkit-scrollbar-track-piece:end {
        background: transparent;
    }
}

.hide-scrollbar {
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

.svg-icon {
    @apply select-none inline-block fill-current flex-shrink-0 transition-icon;
}

.cropzone:not(.moving) .cropzone-transition {
    @apply transition-all;
}

.pixie-root.bg-background{
    background: #181a2b
}
button.hks-navbar-button:hover{
    background: transparent;
}


  *{
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #ccc;
    }
    &::-webkit-scrollbar-thumb {
      // background: $secondary-dim;
      background: #333;
    }
    &::-webkit-scrollbar-thumb:hover{
      // background: $secondary;
      background: #ccc;
    }
  }
  .pagePreloader{
    display: flex;
    justify-content: center;
    align-items: center;
    width:  100vw;
    height:  100vh;
  }
  
  *:disabled{
    cursor: not-allowed;
  }
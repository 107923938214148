@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
// @import "utils/theme.scss";

.reset_password_container{
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#07223F 0%, #54bee8 100%) no-repeat;
  height:  100vh;
  
  .reset_password {
    width: 95vw;
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    margin: 15px 0px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    
    .heading {
      margin-bottom: 10px;
    }
    
    .text {
      font-size: 25px;
    }

    form{
      display: flex;
      flex-direction: column;
      text-align: left;
      width: 100%;
      max-width: 375px;

      label, input, button {
        margin-bottom: 10px;
        display: block;
        width: 100%;
        margin-top: 5px;
      }
      input, button {
        height: 40px;
        border-radius: 7px;
        border: 0px;
        padding: 10px;
        color: #333;
      }
      button{
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        background: #54bee8;
        line-height: 1;

        &:disabled{
          cursor: not-allowed;
        }
      }
    }

  }
}